import React from 'react';
import Avatar from 'semantics/users/Avatar';

const UserThumb = props => {
  const user = props.user;
  const name = props.nameLast || props.nameFirst;
  return(
    <figure className="user__thumb">
      <Avatar user={user} {...props} />
      <figcaption>
        {props.username && <h4 className="user__username">{user.username}</h4>}
        {name && <h2 className="user__name">{props.nameFirst && user.nameFirst} {props.nameLast && user.nameLast}</h2>}
        {props.email && <h3 className="user__email"><a href={`mailto:${user.email}`} >{user.email}</a></h3>}
        {props.disabledEmail && <h3 className="user__email">{user.email}</h3>}
      </figcaption>
    </figure>
  )
}

export default UserThumb;
