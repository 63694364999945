import React from 'react';
import View from 'templates/View';
import { Input } from 'components/inputs/Inputs';
import UserUtil from 'utilis/UserUtil';

import { fetchSingleUser, saveUser } from 'services/data.service';

class SaveUser extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      user: {
        nameFirst: '',
        nameLast: '',
        username: '',
        email: '',
        password: ''
      }
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    console.log(this.props.edit);
    if(this.props.edit) {
      const userId = this.props.match.params.userId;
      fetchSingleUser(userId)
      .then(user => {
        this.setState(state => {
          state.user = {...user};
          return state
        })
      })
    }
  }

  handleChange(e){
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    const name = e.target.name;
    this.setState(state => {
      state.user[name] = value;
      return state;
    })
  }

  handleSubmit(e){
    saveUser(this.state.user);
    e.preventDefault()
  }

  render() {

    const user = this.state.user;

    return(
      <View title={this.props.edit ? `Edit user ${UserUtil.fullName(user)}` : 'Add new user'} className="user-edit" size="sm">
        <form className="form" onSubmit={this.handleSubmit}>

          <Input label="Name First" type="text" name="nameFirst" value={user.nameFirst} onChange={this.handleChange} />
          <Input label="Name Last" type="text" name="nameLast" value={user.nameLast} onChange={this.handleChange} />
          <Input label="Username" type="text" name="username" value={user.username} onChange={this.handleChange} />
          <Input label="Email" type="email" name="email" value={user.email} onChange={this.handleChange} />
          <Input label="Password" type="password" name="password" value={user.password} onChange={this.handleChange} />

          <div className="form-actions">
            <button type="submit" className="btn">{this.props.edit ? 'Save User' : 'Create user'}</button>
          </div>

        </form>
      </View>
    )
  }
}

export default SaveUser;
