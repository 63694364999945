import React from 'react';
import UserThumb from 'semantics/users/UserThumb';
import MaterialIcon from 'material-icons-react';

const MonthlyBillSingleUser = props => {
  const user = props.user;
  const monthId = props.month.id;
  const paidBill = user.payments.find(pay => (pay.monthId === monthId && pay.year === props.refYear))

  return(
    <li key={user.id} className={`month-user${paidBill ? ' bill--paid' : ''}`}>
      <UserThumb user={user} nameFirst nameLast />
      <div className="month-user__pay-status">
        {paidBill ? (
          <span className="icon text-success"><MaterialIcon icon="done" /></span>
        ) : (
          <span>&euro;2.50</span>
        )}
      </div>
    </li>
  )
}

const MonthlyBillsUsers = props => {
  const monthUsers = props.users.map(user => (
    <MonthlyBillSingleUser key={user.id} user={user} {...props} />
  ));

  return monthUsers;
}

const MonthlyBills = props => {
  const month = props.month;

  return(
    <article className="monthly-bills">
      <div className="month-reference">
        <h3 className="month__name">{month.name}</h3>
        <div className="month__bill">
          <h4 className="month__amount">&euro;14.99</h4>
          <small className="month__fee"><em>+ &euro;0.01 commission fee</em></small>
        </div>
      </div>
      <ul className="month-users__list">
        <MonthlyBillsUsers {...props} />
      </ul>
    </article>
  );
}

const MonthlyBillsList = props => {
  const months = props.months.map(month => (
    <MonthlyBills key={month.id} month={month} {...props} />
  ));

  return <div className="monthly-bills__list">{months}</div>;
}

export default MonthlyBillsList;
