import React from 'react';

function Table(props) {
  const headers = props.headers ? props.headers.map((h, i) => (
    <th key={i} className={h.className}>{h.label}</th>
  )) : null;

  return(
    <div className="table-wrapper">
      <table className={`table table-${props.className ? props.className : 'standard'}`} cellSpacing="0">
        <thead>
          <tr>{headers}</tr>
        </thead>
        <tbody>
          {props.data}
        </tbody>
      </table>
    </div>
  )
}

export default Table;
