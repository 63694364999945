import React from 'react';
import View from 'templates/View';
import UsersThumbs from 'semantics/users/UsersThumbs';
import MonthlyBillsList from 'semantics/months/MonthlyBillsList';

import { fetchUsers, fetchMonthsRefs } from 'services/data.service';

class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      users: [],
      months: [],
      showLastYear: false,
    }

    this.currentYear = new Date().getFullYear();
    this.lastYear = this.currentYear - 1;

    this.toggleLastYear = this.toggleLastYear.bind(this);
  }

  componentDidMount() {
    fetchUsers()
    .then(users => {
      this.setState(state => ({users: users}))
    })

    fetchMonthsRefs()
    .then(months => {
      this.setState(state => ({months: months}))
    })
  }

  toggleLastYear() {
    this.setState({showLastYear: !this.state.showLastYear})
  }

  render() {
    return(
      <View title="Hi Vehda family," className="dashboard">

        <section className="dashboard__entry">
          <p className="lead">
            This is your personal payments dashboard
          </p>
          <p>
            Check your members activities and pending bills =)
          </p>
        </section>

        <UsersThumbs users={this.state.users} />

        <section className="monthly-bills__feed">
          <h2>Total payments in {this.currentYear}</h2>
          <MonthlyBillsList months={this.state.months} users={this.state.users} refYear={this.currentYear} />
        </section>

        {this.state.showLastYear &&
          <section className="monthly-bills__feed">
            <h2>Last year payments - {this.lastYear}</h2>
            <MonthlyBillsList months={this.state.months} users={this.state.users} refYear={this.lastYear} />
          </section>
        }

        <div className="cta-block text-center">
          <button onClick={this.toggleLastYear} className="btn btn-round">
            {!this.state.showLastYear ? `Show payments for ${this.lastYear}` : 'Hide last year payments'}
          </button>
        </div>
      </View>
    )
  }
}

export default Dashboard;
