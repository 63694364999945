import React from 'react';
import View from 'templates/View';
import UserUtil from 'utilis/UserUtil';
import PaymentsTable from 'semantics/payments/PaymentsTable';

import { fetchPayments, fetchUsersRefs } from 'services/data.service';

class Payments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payments: [],
      userPayments: [],
      users: [],
      visibleFilters: false,
      isFiltered: false,
      activeUser: {}
    }

    this.isMobile = window.innerWidth <= 768;

    this.toggleFiltersBox = this.toggleFiltersBox.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
  }

  componentDidMount() {

    if(!this.isMobile) {
      this.setState({visibleFilters: true})
    }

    fetchPayments()
    .then(payments => {
      this.setState(state => ({payments: payments}))
    })

    fetchUsersRefs()
    .then(users => {
      this.setState(state => ({users: users}))
    })
  }

  toggleFiltersBox() {
    this.setState(state => ({visibleFilters: !this.state.visibleFilters}))
  }

  filterPayments(user) {
    this.setState(state => ({
      userPayments: state.payments.filter(pay => pay.user.id === user.id),
      isFiltered: true,
      activeUser: user,
    }))

    if(this.isMobile) {
      this.setState({visibleFilters: false})
    }
  }

  resetFilters() {
    this.setState(state => ({
      userPayments: [],
      isFiltered: false,
      activeUser: {},
    }))

    if(this.isMobile) {
      this.setState({visibleFilters: false})
    }
  }

  render() {
    let pays = this.state.userPayments.length > 0 ? this.state.userPayments : this.state.payments;
    let noUserPayments = (this.state.isFiltered && this.state.userPayments.length === 0);

    const usersFilters = this.state.users.map(user => (
      <button
        className={this.state.activeUser.id === user.id ? 'active' : undefined}
        key={user.id}
        onClick={this.filterPayments.bind(this, user)}
      >
        {user.nameFirst} {user.nameLast}
      </button>
    ));

    return(
      <View title="Payments list" className="payments-view">

        <div className="filters__wrapper">
          {this.isMobile &&
            <div className="filters-toggle">
              <button onClick={this.toggleFiltersBox} className={this.state.visibleFilters ? 'active' : ''}>
                {this.state.activeUser.id ? UserUtil.initials(this.state.activeUser): 'All'}
              </button>
            </div>
          }

          {this.state.visibleFilters &&
            <div className="table-filters">
              <button onClick={this.resetFilters}>All Payments</button>
              {usersFilters}
            </div>
          }
        </div>

        { !noUserPayments ? (
          <PaymentsTable payments={pays} isFiltered={this.state.isFiltered} />
        ) : (
          <div className="banner">No payments yet</div>
        )}
      </View>
    )
  }
}

export default Payments;
