import React from 'react';
import UserPaymentsTable from 'semantics/payments/UserPaymentsTable';
import UserThumb from 'semantics/users/UserThumb';

import AppHeader from 'templates/partials/AppHeader';
import AdminActions from 'templates/partials/AdminActions';
import AppFooter from 'templates/partials/AppFooter';

import AuthContext from 'context/auth.context';

import { fetchSingleUser } from 'services/data.service';

import { family } from 'config';

class User extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      user: {},
      payments: []
    }
  }

  componentDidMount() {
    const userId = this.context.user.id;
    fetchSingleUser(userId)
    .then(user => {
      this.setState(state => ({user: user, payments: user.payments}))
    })
  }

  render() {
    const user = this.state.user;
    const payments = this.state.payments;

    return(
      <>
        <AppHeader />

        {this.context.user.isAdmin && <AdminActions />}

        <main className="app__main">
          <div className="view view--user-detail">
            <div className="app__container--sm">

              <UserThumb user={user} nameFirst nameLast username email />

              <section className="user__payments">
                <h2>My paid bills</h2>
                <UserPaymentsTable payments={payments} />
              </section>
            </div>
          </div>
        </main>

        <AppFooter family={family} />
      </>
    )
  }
}

export default User;
