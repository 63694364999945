import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import AuthContext from 'context/auth.context';

import Login from 'views/Login';
import Dashboard from 'views/Dashboard';
import Payments from 'views/Payments';
import SavePayment from 'views/SavePayment';
import Users from 'views/Users';
import User from 'views/User';
import CurrentUser from 'views/CurrentUser';
import SaveUser from 'views/SaveUser';

function AdminRoute({ component: Component, ...rest }) {
  return (
    <AuthContext.Consumer>
      {(context) => (
        <Route
          {...rest}
          render={props =>
            context.user.isAdmin ? (
              <Component {...props} {...rest} />
            ) : (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: props.location }
                }}
              />
            )
          }
        />
      )}
    </AuthContext.Consumer>
  );
}

class AppRouter extends React.Component  {

  static contextType = AuthContext;

  render() {

    return(
      <Router>
        <div className="app">

          <Switch>

            <Route path='/login' component={Login} />

            {!this.context.token && <Redirect to="/login" />}

            <AdminRoute path='/family/add' exact component={SaveUser} />
            <AdminRoute path='/family/:userId/edit' exact edit component={SaveUser} />
            <AdminRoute path='/payments/add' component={SavePayment} />
            <AdminRoute path='/payments/:paymentId/edit' edit component={SavePayment} />

            <Route path='/family/:userId' component={User} />
            <Route path='/' exact component={Dashboard} />
            <Route path='/family' exact component={Users} />
            <Route path='/payments' exact component={Payments} />

            {this.context.token && !this.context.user.isFamilyMaster && <Route path='/me' exact component={CurrentUser} />}

          </Switch>

        </div>
      </Router>
    )
  }
}

export default AppRouter;
