import React from 'react';
import logo from 'assets/img/logo/logo_white.png';

const ViewAuth = props => {
  return (
    <div className={`view--auth view--${props.className}`}>
      <div className="app__container--xs">
        <figure className="brand" >
          <img src={logo} alt="spotipay" title="spotipay" />
          <figcaption>
            <h1>Spotipay <span>{props.title}</span></h1>
          </figcaption>
        </figure>
        {props.children}
      </div>
    </div>
  )
}

export default ViewAuth;
