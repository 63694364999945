import React from 'react';

class Carousel extends React.Component {
  constructor(props) {
    super(props);

    this.carousel = this.props.images;
    this.options = {
      interval: this.props.interval || 3000,
      transition: this.props.transition || 2000
    }

    this.runningCarousel = null;

    this.state = {
      activeImg: 0
    }
  }

  componentDidMount() {
    this.subscribeToCarousel()
  }

  componentWillUnmount() {
    this.unsubscribeFromCarousel()
  }

  initCarousel() {
    let i = 0;

    return setInterval(() => {
      i < this.carousel.length - 1 ? i ++ : i = 0;
      this.setState({activeImg: i})
    }, this.options.interval)
  }

  subscribeToCarousel() {
    this.runningCarousel = this.initCarousel();
  }

  unsubscribeFromCarousel() {
    clearInterval(this.runningCarousel);
  }

  render() {
    const transitionTiming = this.options.transition / 1000 + 's';
    const images = this.carousel.map((image, i) => (
      <img
        src={image}
        alt={this.props.title}
        title={this.props.title}
        className={this.state.activeImg === i ? 'active' : undefined}
        style={{transition: `all ${transitionTiming} ease`}}
        key={i}
      />
    ))

    return (
      <div className="carousel">
        {images}
      </div>
    )
  }
}

export default Carousel;
