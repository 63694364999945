import React from 'react';

export class Input extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.props.onChange(e);
  }

  render() {
    return(
      <div className="form-input">
        <label htmlFor={this.props.name}>{this.props.label}</label>
        <input {...this.props}/>
      </div>
    )
  }
}

export class Select extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.props.onChange(e);
  }

  render() {
    return(
      <div className="form-input">
        <label htmlFor={this.props.name}>{this.props.label}</label>
        <select {...this.props}>
          {this.props.children}
        </select>
      </div>
    )
  }
}
