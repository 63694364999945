import React from 'react';
import UserUtil from 'utilis/UserUtil';

const PaymentsTable = props => {
  const isMobile = window.innerWidth <= 768;
  
  const headers = (
    <>
      <div className="ft__th ft__id"></div>
      {!props.isFiltered && <div className="ft__th">Member</div>}
      <div className="ft__th">Month</div>
      <div className="ft__th text-right">Year</div>
      <div className="ft__th">Amount</div>
    </>
  );

  const payments = props.payments.map(pay => (
    <div key={pay.id} className="ft__row">
      <div className="ft__td ft__id">{pay.id}</div>
      {!props.isFiltered && <div className="ft__td text-capitalize">{isMobile ? UserUtil.initials(pay.user) : UserUtil.fullName(pay.user)}</div>}
      <div className="ft__td text-capitalize">
        {isMobile
          ? (props.isFiltered ? pay.month.name : pay.month.short)
          : pay.month.name
        }
      </div>
      <div className="ft__td text-right">{pay.year}</div>
      <div className="ft__td">&euro;{pay.amount}</div>
    </div>
  )).reverse();

  return (
    <div className="flextable">
      <header>{headers}</header>
      <main>{payments}</main>
    </div>
  )
}

export default PaymentsTable;
