import React from 'react';
import ViewAuth from 'templates/ViewAuth';
import { Input } from 'components/inputs/Inputs';
import Carousel from 'components/carousels/Carousel';

import AuthContext from 'context/auth.context';

import guitar from 'assets/img/backgrounds/guitar.jpg'
import concert from 'assets/img/backgrounds/concert.jpg'
import singer from 'assets/img/backgrounds/singer.jpg'
import bass from 'assets/img/backgrounds/bass.jpg'
import drums from 'assets/img/backgrounds/drums.jpg'
import guitarWall from 'assets/img/backgrounds/guitar_wall.jpg'
import microphone from 'assets/img/backgrounds/microphone.jpg'

import { authenticate } from 'services/auth.service';

class Login extends React.Component {

  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if(this.context.token) {
      this.props.history.push('/');
    }
  }

  handleChange(e) {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    const name = e.target.name;
    this.setState({[name]: value})
  }

  handleSubmit(e) {
    e.preventDefault();

    authenticate(this.state)
    .then(res => {
      sessionStorage.setItem('sp_token', res.token);
      sessionStorage.setItem('sp_current_user', JSON.stringify(res.user));
      return this.context.login(res.token, res.user);
    })
    .then(res => this.props.history.push('/'))
  }

  render() {
    const { email, password } = this.state;

    const carousel = [
      guitar,
      concert,
      singer,
      bass,
      drums,
      guitarWall,
      microphone
    ];

    return(
      <ViewAuth title="Login" className="login">
        <Carousel title="login-carousel" images={carousel} interval="5000" />
        <form className="form form--auth" onSubmit={this.handleSubmit}>
          <Input label="Email" type="email" name="email" value={email} onChange={this.handleChange} />
          <Input label="Password" type="password" name="password" value={password} onChange={this.handleChange} />

          <div className="form-actions">
            <button type="submit" className="btn-primary btn-round">Login</button>
          </div>
        </form>
      </ViewAuth>
    )
  }
}

export default Login;
