import React from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import MaterialIcon from 'material-icons-react';
import Avatar from 'semantics/users/Avatar';
import { logout } from 'services/auth.service';

import UserUtil from 'utilis/UserUtil';

import AuthContext from 'context/auth.context';

import logo from 'assets/img/logo/logo_green.png';

class AppHeader extends React.Component{

  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
  }

  logout() {
    logout(this.props.history)
    .then(() => {
      sessionStorage.removeItem('sp_token');
      sessionStorage.removeItem('sp_current_user');
      this.context.logout()
    });
  }

  render() {
    const currentUser = this.context.user;

    return(
      <header className="app__header">
        <nav className="app__navigator">
          <Link to="/">
            <figure className="brand" >
              <img src={logo} alt="spotipay" title="spotipay" />
              <figcaption>
                Spotipay <span>for Families</span>
              </figcaption>
            </figure>
          </Link>
          <ul>
            <li>
              <NavLink to="/" activeClassName="active" exact={true}>
                <MaterialIcon icon="dashboard"/>
                <span>dashboard</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/payments" exact activeClassName="active">
                <MaterialIcon icon="account_balance"/>
                <span>payments</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/family" activeClassName="active">
                <MaterialIcon icon="supervisor_account"/>
                <span>family</span>
              </NavLink>
            </li>
            {!currentUser.isFamilyMaster &&
              <li>
                <NavLink to="/me" activeClassName="active">
                  <MaterialIcon icon="person"/>
                  <span>My payments</span>
                </NavLink>
              </li>
            }
            <li>
              <button onClick={this.logout} className="btn-danger btn-icon btn-plain">
                <MaterialIcon icon="exit_to_app"/>
              </button>
            </li>
          </ul>
          <div className="app__current-user">
            <span className="app__current-user__welcome">
              Welcome back, {UserUtil.capitalize(currentUser.nameFirst)}
            </span>
            <Avatar user={currentUser} />
          </div>
        </nav>
      </header>
    )
  }
}

export default withRouter(AppHeader);
