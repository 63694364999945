import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import swal from '@sweetalert/with-react';
import UserPaymentsTable from 'semantics/payments/UserPaymentsTable';
import UserThumb from 'semantics/users/UserThumb';
import UserUtil from 'utilis/UserUtil';

import { AdminComponent } from 'Protected';

import AppHeader from 'templates/partials/AppHeader';
import AdminActions from 'templates/partials/AdminActions';
import AppFooter from 'templates/partials/AppFooter';

import AuthContext from 'context/auth.context';

import { fetchSingleUser, deleteUser } from 'services/data.service';

import { family } from 'config';

class User extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      user: {},
      payments: [],
      redirect: false
    }

    this.deleteUser = this.deleteUser.bind(this);
  }

  componentDidMount() {
    const userId = this.props.match.params.userId;
    fetchSingleUser(userId)
    .then(user => {
      this.setState(state => ({user: user, payments: user.payments}))
    })
  }

  deleteUser() {
    const dialog = {
      title: `Delete this user ?`,
      text: `Do you want to delete te user ${UserUtil.fullName(this.state.user)}`,
      buttons: [ 'Cancel', 'Confirm' ],
      dangerMode: true
    }

    swal(dialog)
    .then(() => deleteUser(this.state.user))
    .then(() => {
      this.setState(state => ({redirect: true}))
    })
  }

  render() {
    const user = this.state.user;
    const payments = this.state.payments;

    if (this.state.redirect) return <Redirect to='/family' />

    return(
      <>
        <AppHeader />

        {this.context.user.isAdmin && <AdminActions />}

        <main className="app__main">
          <div className="view view--user-detail">
            <div className="app__container--sm">

              <div className="breadcrumbs text-center">
                <Link to="/family">‹ back to family</Link>
              </div>

              <UserThumb user={user} nameFirst nameLast username email />

              <AdminComponent>
                <div className="admin__user-action text-center">
                  <Link to={`${this.props.match.url}/edit`} className="btn">Edit {user.nameFirst}</Link>
                  <button onClick={this.deleteUser} className="btn-danger">Delete {user.nameFirst}</button>
                </div>
              </AdminComponent>

              <section className="user__payments">
                <h2>{user.nameFirst}'s paid bills</h2>
                <UserPaymentsTable payments={payments} />
              </section>
            </div>
          </div>
        </main>

        <AppFooter family={family} />
      </>
    )
  }
}

export default User;
