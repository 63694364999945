import React from 'react';
import Table from 'components/tables/Table';

const UserPaymentsTable = props => {

  const payments = props.payments.map( pay => (
    <tr key={pay.id} className="payment">
      <td className="row-id"><span>{pay.id}</span></td>
      <td className="text-capitalize">{pay.month.name}</td>
      <td className="text-right">{pay.year}</td>
      <td className="payment__price">&euro; {pay.amount}</td>
    </tr>
  )).reverse();

  return(
    <>
      { payments.length > 0 ? (
        <Table
          headers={[
            { label: '', className: 'row-id__header' },
            { label: 'Month' },
            { label: 'Year', className: 'text-right' },
            { label: 'Import', className: 'text-right' },
          ]}
          data={payments}
        />
      ) : (
        <div className="banner">No payments yet</div>
      )}
    </>
  )

}

export default UserPaymentsTable;
