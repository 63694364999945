import React from 'react';

function AppFooter(props) {
  return(
    <footer className="app__footer">
      <span>Copyright © {new Date().getFullYear()} {props.family.name}.&nbsp; <a href={`mailto:${props.family.email}`}>vehda.offical@gmail.com</a>.&nbsp;</span>
      <span>Thanks to Spotify Family®</span>
    </footer>
  )
}

export default AppFooter;
