import swal from '@sweetalert/with-react';
import UserUtil from 'utilis/UserUtil';

export const fetchData = endpoint => {
  return fetch(endpoint)
  .then(res => res.json())
}

export const postData = (endpoint, data, msg) => {
  return fetch(endpoint, {
    method: 'post',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  })
  .then(res => res.json())
  .then(res => {
    if(res.error) {
      throw new Error(res.error);
    }
    return res;
  })
  .then(res => swal({
    title: msg.title,
    text: msg.text,
    icon: "success",
  }))
  .catch(err => {
    swal({
      text: err.toString().substring(7), // remove 'Error: ' from error string
      icon: 'warning',
    })
  })
}

// GET DATAS

// payments
export const fetchPayments = () => {
  return fetchData('/api/payments');
}

// single payment
export const fetchSinglePayment = payId => {
  return fetchData('/api/payments/' + payId);
}

// users
export const fetchUsers = () => {
  return fetchData('/api/users');
}

// users ref
export const fetchUsersRefs = () => {
  return fetchData('/api/users/ref');
}

// single user
export const fetchSingleUser = userId => {
  return fetchData('/api/users/' + userId);
}

//  months
export const fetchMonths = () => {
  return fetchData('/api/months');
}

// months ref
export const fetchMonthsRefs = () => {
  return fetchData('/api/months/ref');
}

// POST DATAS

// save payment
export const savePayment = payment => {
  if(payment.id) {
    postData(
      '/api/payments/edit',
      payment,
      {
        title: 'Payment Updated',
        text: 'Your payment has been successfully updated'
      }
    );
  } else {
    postData(
      '/api/payments/create',
      payment,
      {
        title: 'Payment Saved',
        text: 'Your payment has been successfully saved'
      }
    );
  }
}

// save user
export const saveUser = user => {
  if(user.id) {
    postData(
      '/api/users/edit',
      user,
      {
        title: 'User Modified',
        text: `${UserUtil.fullName(user)} has been successfully modified`
      }
    );
  } else {
    postData(
      '/api/users/create',
      user,
      {
        title: 'User Created',
        text: `${UserUtil.fullName(user)} has been created`
      }
    );
  }
}

export const deleteUser = user => {
  postData(
    '/api/users/delete',
    user,
    {
      title: 'User Deleted',
      text: `${UserUtil.fullName(user)} has been deleted`
    }
  )
}

export const checkToken = () => {
  fetch('/api/token/check')
  .then(res => res.text())
  .then(token => console.log(token))
}
