import React from 'react';
import { Link } from 'react-router-dom';
import MaterialIcon from 'material-icons-react';

const AdminActions = props => {

  return(
    <ul className="admin-actions">
      <li className="admin__action">
        <Link to="/payments/add" className="btn btn-icon">
          <MaterialIcon icon="account_balance_wallet"/>
        </Link>
        </li>
      <li className="admin__action">
        <Link to="/family/add" className="btn btn-icon">
          <MaterialIcon icon="person"/>
        </Link>
      </li>
    </ul>
  )
}

export default AdminActions;
