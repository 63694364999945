import React from 'react';
import View from 'templates/View';
import { Input, Select } from 'components/inputs/Inputs';

import { fetchUsersRefs, fetchMonthsRefs, fetchSinglePayment, savePayment } from 'services/data.service';

class SavePayment extends React.Component {
  constructor() {
    super();
    this.state = {
      payment: {
        amount: 2.50,
        userId: 1,
        monthId: new Date().getMonth() + 1,
        year: new Date().getFullYear()
      },
      users: [],
      months: []
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    if( this.props.edit ) {
      this.fetchPayment()
    };
    this.fetchUsers();
    this.fetchMonths();
  }

  fetchPayment() {
    const paymentId = this.props.match.params.paymentId;
    fetchSinglePayment(paymentId)
    .then(payment => {
      this.setState(state => {
        state.payment = {...payment};
        return state
      })
    })
  }

  fetchUsers() {
    fetchUsersRefs()
    .then(users => {
      this.setState(state => ({users: users}))
    })
  }

  fetchMonths() {
    fetchMonthsRefs()
    .then(months => {
      this.setState(state => ({months: months}))
    })
  }

  handleChange(e) {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    const name = e.target.name;
    this.setState(state => {
      state.payment[name] = value;
      return state;
    })
  }

  handleSubmit(e) {
    savePayment(this.state.payment);
    console.log(this.state.payment);
    e.preventDefault();
  }

  render() {
    const payment = this.state.payment;

    const userSelect = this.state.users.map(user => (
      <option key={user.id} value={user.id}>{user.nameFirst} {user.nameLast}</option>
    ));

    const monthSelect = this.state.months.map(month => (
      <option key={month.id} value={month.id}>{month.name}</option>
    ));

    return(
      <View title={this.props.edit ? 'Edit payment' : 'Add new payment'} className="payment-add" size="sm">
        <form className="form" onSubmit={this.handleSubmit}>

          <Input label="Payment amount" type="number" steps="2" name="amount" value={payment.amount} onChange={this.handleChange} />
          <Select label="Paying member" value={payment.userId} name="userId" onChange={this.handleChange}>
            {userSelect}
          </Select>
          <Select label="Validity month" value={payment.monthId} name="monthId" onChange={this.handleChange}>
            {monthSelect}
          </Select>
          <Input label="Validity year" type="number" name="year" value={payment.year} onChange={this.handleChange} />

          {/* <div className="form-input">
            <label htmlFor="name">Payment amount</label>
            <input type="number" steps="2" name="amount" value={payment.amount} onChange={this.handleChange} />
            </div>

            <div className="form-input">
            <label htmlFor="userId">Paying member</label>
            <select value={payment.userId} name="userId" onChange={this.handleChange}>
              {userSelect}
            </select>
            </div>

            <div className="form-input">
            <label htmlFor="monthId">Validity month</label>
            <select value={payment.monthId} name="monthId" onChange={this.handleChange}>
              {monthSelect}
            </select>
            </div>

            <div className="form-input">
            <label htmlFor="year">Validity year</label>
            <input type="number" name="year" value={payment.year} onChange={this.handleChange} />
          </div> */}

          <div className="form-actions">
            <button type="submit" className="btn">{this.props.edit ? 'Save Payment' : 'Add new payment'}</button>
          </div>
        </form>
      </View>
    )
  }
}

export default SavePayment;
