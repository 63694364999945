import React from 'react';
import { Link } from 'react-router-dom';
import View from 'templates/View';
import UserThumb from 'semantics/users/UserThumb';

import { fetchUsers } from 'services/data.service';

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: []
    }
  }

  componentDidMount() {
    fetchUsers()
    .then(users => {
      this.setState(state => ({users: users}))
    })
  }

  lastYearPayments(payments) {
    return payments.filter(pay => pay.year === new Date().getFullYear());
  }

  checkPayments(payments, label, errLabel) {
    if(payments.length > 0) {
      return payments.length + ' ' + label;
    }
    else {
      return errLabel;
    }
  }

  render() {
    const users = this.state.users.map(user => (
      <li key={user.id} className="users-list__item">
        <Link to={`/family/${user.id}`}>
          <UserThumb user={user} nameLast nameFirst disabledEmail/>
          <div className="user__payments">
            <span className="user__payments__total">
              {this.checkPayments(user.payments, 'total payments', 'No payments yet')}
            </span>
            <span className="user__payments__last-year">
              {this.checkPayments(
                this.lastYearPayments(user.payments),
                'in the last year',
                'No payments in the last year'
              )}
            </span>
          </div>
        </Link>
      </li>
    ));

    return(
      <View title="Family members" className="users">
        <ul className="users-list">{users}</ul>
      </View>
    )
  }
}

export default Users;
