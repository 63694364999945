import React from 'react';
// import Cookies from 'js-cookie';
import AppRouter from 'AppRouter';
import AuthContext from 'context/auth.context';

// MAIN APP COMPONENT
class App extends React.Component {
  constructor() {
    super();
    this.state = {
      token: sessionStorage.getItem('sp_token'),
      user: JSON.parse(sessionStorage.getItem('sp_current_user')),
    }

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }

  login(token, user) {
    this.setState({
      token: token,
      user: user,
    })
  }

  logout() {
    this.setState({
      token: null,
      user: null,
    })
  }

  render() {
    return (
      <AuthContext.Provider value={{
        token: this.state.token,
        user: this.state.user,
        login: this.login,
        logout: this.logout
      }}>
        <AppRouter />
      </AuthContext.Provider>
    )
  }
}

export default App;
