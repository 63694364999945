import React from 'react';
import { withRouter } from 'react-router-dom';
import { checkAuth } from 'services/auth.service';

import Loading from 'components/helpers/Loading';

class ProtectedRoutesController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: undefined
    }
  }

  componentDidMount() {
    checkAuth()
    .then(user => {
      this.setState({user: user})
    })
  }

  render() {
    const {user} = this.state;

    if(user === undefined) {
      return <Loading />
    }

    if(!user.isAuthenticated) {
      this.props.history.push('/login');
    }

    return this.props.children
  }
}

export const ProtectedRoutes = withRouter(ProtectedRoutesController);

class AdminRoutesController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: false
    }
  }

  componentDidMount() {
    checkAuth()
    .then(user => {
      this.setState({isAdmin: user.isAdmin})
    })
  }

  render() {
    if(!this.state.isAdmin) {
      this.props.history.push('/');
    }

    return this.props.children
  }
}

export const AdminRoutes = withRouter(AdminRoutesController);

export class AdminComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: false
    }
  }

  componentDidMount() {
    checkAuth()
    .then(user => {
      this.setState({isAdmin: user.isAdmin})
    })
  }

  render() {
    if(!this.state.isAdmin) {
      return null
    }

    return this.props.children
  }
}
