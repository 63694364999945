class UserUtil {

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  fullName(user) {
    return this.capitalize(user.nameLast) + ' ' + this.capitalize(user.nameFirst);
  }

  initials(user) {
    const initials = user.nameFirst.substr(0,1) + user.nameLast.substr(0,1);
    return initials.toUpperCase();
  }
}

export default new UserUtil();
