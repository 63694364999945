import React from 'react';
import UserThumb from 'semantics/users/UserThumb';

function UsersThumbs(props) {
  const users = props.users.map(user => (
    <div key={user.id} className="user-thumb__wrapper">
      <UserThumb user={user} nameFirst link={`/family/${user.id}`} />
    </div>
  ));

  return(
    <section className="users__thumbs">{users}</section>
  )
}

export default UsersThumbs;
