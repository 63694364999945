import React from 'react';
import { Link } from 'react-router-dom';

import AppHeader from 'templates/partials/AppHeader';
import AdminActions from 'templates/partials/AdminActions';
import AppFooter from 'templates/partials/AppFooter';

import AuthContext from 'context/auth.context';

import { family } from 'config';

class View extends React.Component {
  static contextType = AuthContext;

  render() {
    const containerSize = this.props.size ? `app__container--${this.props.size}` : 'app__container';
    return (
      <>
        <AppHeader />

        {this.context.user.isAdmin && <AdminActions />}

        <main className="app__main">
          <div className={`view view--${this.props.className}`} {...this.props.tags}>
            <div className={containerSize}>
              {this.props.backTo && <Link to={this.props.backTo}>Go back</Link>}
              <h1 className="view-title">{this.props.title}</h1>
              {this.props.children}
            </div>
          </div>
        </main>

        <AppFooter family={family} />
      </>
    )
  }
}

export default View;
