import React from 'react';
import { Link } from 'react-router-dom';
import placeholder from 'assets/img/placeholder/avatar.jpg';

const Avatar = props => {
  const user = props.user;
  let avatar;
  const thumb = (
    <img
      src={user.image ? user.image : placeholder}
      alt={`${user.nameFirst} ${user.nameLast}`}
      title={`${user.nameFirst} ${user.nameLast}`}
    />
  );

  if(props.link) {
    avatar = <Link to={props.link} className="user__avatar">{thumb}</Link>
  } else {
    avatar = <div className="user__avatar">{thumb}</div>
  }

  return avatar;
}

export default Avatar;
