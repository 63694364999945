import swal from '@sweetalert/with-react';

export const checkAuth = () => {
  const notAuthUser = {
    email: '',
    isAuthenticated: false,
    isAdmin: false,
  }

  return fetch('/api/token/check')
  .then(res => {
    if(res.status === 200) {
      return res.json()
    } else {
      return notAuthUser;
    }
  })
  .then(authUser => {
    return {...authUser};
  })
}

// Login
export const authenticate = (user) => {
  return fetch('/api/authenticate', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(user)
  })
  .then(res => res.json())
  .then(res => {
    if(res.error) {
      throw new Error(res.error);
    }
    return res;
  })
  .catch(err => {
    // swal({
    //   title: err.toString().substring(7), // remove 'Error: ' from error string
    //   icon: 'error',
    // })
    return Promise.reject('authentication error').then(swal({
      title: err.toString().substring(7), // remove 'Error: ' from error string
      icon: 'error',
    }));
  })
}

export const logout = history => {
  return swal({
    title: 'Log out?',
    text: 'You\'ll be redirect to login page',
    buttons: ['Cancel', 'Yes, logout'],
    dangerMode: true
  })
  .then(confirmed => {
    if(confirmed) {
      fetch('/api/token/delete')
      .then(res => {
        if(res.status === 200) {
          history.push('/login');
        } else {
          throw new Error(res.error);
        }
        return res;
      })
    }
  })
  .catch(err => {
    return Promise.reject('logout error').then(swal({
      title: err.toString().substring(7), // remove 'Error: ' from error string
      icon: 'error',
    }));
  })
}
